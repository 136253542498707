<template>
  <footer class="px-4 text-center">
    <p class="my-4">
      © 2024 Управление охотничьего хозяйства Курганской области
    </p>

    <ul class="list-inline m-0">
      <li>г.Курган, ул. Володарского, 65 стр.1, кабинет 102</li>
      <li class="list-inline-item">Телефон приёмной: 43-19-00</li>
      <li class="list-inline-item">Телефон канцелярии: 41-37-07</li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped></style>
