var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('router-view',{attrs:{"url":_vm.dynamicUrl,"config":_vm.config,"config-loaded":_vm.configLoaded,"user":_vm.config.user,"unread-messages":_vm.unreadMessages,"theme":_vm.config.theme,"apps":_vm.apps,"settings-form":_vm.settingsForm,"services":_vm.services,"service":_vm.service,"loaded-service-form":_vm.loadedServiceForm},on:{"open-auth-modal":_vm.openAuthModal,"sign-out":_vm.signOut,"assign-user":function($event){return _vm.assignUser($event)},"select-role":function($event){_vm.user.selectedRole = $event},"change-apps-page-size":function($event){return _vm.changePageSize(_vm.apps, $event)},"change-apps-page":function($event){return _vm.changePage(_vm.apps, $event)},"change-apps-filter":function($event){return _vm.changeForm(_vm.apps.filters.form, $event)},"apply-apps-filter":function($event){return _vm.applyFilter(_vm.apps)},"clear-apps-filter":function($event){return _vm.clearFilter(_vm.apps)},"change-user-role":function($event){return _vm.changeUserRole($event[0])},"change-user-org":function($event){return _vm.changeUserOrg($event[0])},"change-settings-form-part1":function($event){return _vm.changeFormWithValidate(
        _vm.settingsForm.notification.form,
        $event,
        'changeSettingsNotification'
      )},"change-settings-form-part2":function($event){return _vm.changeFormWithValidate(
        _vm.settingsForm.server.form,
        $event,
        'changeSettingsServer'
      )},"change-settings-form-part3":function($event){return _vm.changeForm(_vm.settingsForm.logo.form, $event)},"change-settings-form-part4":function($event){return _vm.changeFormWithValidate(
        _vm.settingsForm.footer.form,
        $event,
        'changeSettingsFooter'
      )},"set-config":_vm.setConfig,"reload-apps":_vm.getApps,"show-service-first-form":function($event){return _vm.showServiceFirstForm($event)},"show-app-form":function($event){return _vm.showAppForm($event)},"change-app-form":function($event){return _vm.changeAppForm($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }